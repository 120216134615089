import React from "react"

const Menu = (props) => {
  return (
    <div className="menu-bar">
      <div className="menu-logo">
        <h2 className="menu-title">Fernando Costa Portfólio</h2>
      </div>
    </div>
  )
};

export default Menu;
